import { Component } from 'vue-property-decorator'

import CommonLayoutMixin from 'booksprout-app/src/components/mixins/commonLayout'
import MenuItem from '../menuItem.interface'
import { WebsitePaths } from '../../../router/path.constants'

@Component
export default class BaseLayoutMixin extends CommonLayoutMixin {
  get menuItems (): MenuItem[] {
    const items: MenuItem[] = []

    items.push({
      title: 'Support',
      url: 'https://help.booksprout.co/',
      showInFooter: true,
      isExternal: true
    }, {
      title: 'About Us',
      url: WebsitePaths.about,
      showInFooter: true
    }, {
      title: 'Roadmap',
      url: 'https://feedback.booksprout.co/roadmap',
      showInFooter: true,
      isExternal: true
    },
    {
      title: 'Affiliate Program',
      url: WebsitePaths.affiliateProgram,
      showInFooter: true
    },
    {
      title: 'Blog',
      url: 'https://booksprout.co/fivestar',
      showInFooter: true,
      isExternal: true
    }, {
      title: 'for Reviewers',
      url: WebsitePaths.readers,
      showInHeader: true
    }, {
      title: 'for Authors',
      url: WebsitePaths.home,
      showInHeader: true
    }, {
      title: 'Pricing',
      url: WebsitePaths.pricing,
      showInHeader: true,
      showInFooter: true
    }, {
      title: 'Sign In',
      url: WebsitePaths.login,
      isExternal: true,
      showInHeader: true
    }, {
      title: 'Sign Up',
      url: WebsitePaths.signUp,
      isExternal: true,
      showInHeader: true
    }, {
      title: 'Download the app',
      highlight: true,
      actsAsButton: true,
      showInFooter: true
    })

    return items
  }
}
